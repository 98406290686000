<template>
  <div v-if="fokusVisible">
    <h2 v-if="!storagePath" class="tab-header">Fokus</h2>
    <div v-if="!storagePath" class="content-empty">{{$t('pages.taskDetails.noTaskNumber')}}</div>
    <section class="embed-responsive embed-responsive-16by9" style="padding: 22.25% 51%">
      <div class="wrap">
        <iframe
          v-if="storagePath"
          class="embed-responsive-item"
          :src="`${fokusUrl}?contractorApp=${service}&taskId=${task.id}&userToken=${$store.state.base.token.split(' ')[1]}&userRefreshToken=${$store.state.base.refreshToken}&storagePath=${storagePath}&lang=${$i18n.locale}`"
          allow="microphone; camera"
        />
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import api from "@/api";
import {TYPE_NEW, TYPE_OLD} from '@/components/bundles/share/fokus-iframe/fokusIframeTypes.js'

export default {
  props: {
    task: {},
    service: { type: String, required: true },
    type: {type: Number, default: () => TYPE_OLD}
  },
  data () {
    return {
      fokusUrl: '',
      fokusVisible: false
    }
  },
  computed: {
    storagePath () {
      if (this.task.number) {
        return moment(this.task.createdAt).format('YYYYMM') + '/' + this.task.number.split('/').join('_')
      } else {
        return false
      }
    },
  },
  mounted() {
    this.getFokusURL()
  },
  methods: {
    getFokusURL () {
      if (['extemn','extpko','intgen','intbls','intvhs'].includes(this.service)) {
        api.request(this.service, 'get', `/fokus-operator-url${this.type === TYPE_NEW ? `-new` :  `` }`)
        .then((result) => {
          this.fokusUrl = result.data.operator_url
          this.fokusVisible = true
        })
        .catch((error) => {
          console.error(error)
          this.fokusUrl = process.env.VUE_APP_FOKUS_URL
          this.fokusVisible = true
        })
        return
      }
      this.fokusUrl = process.env.VUE_APP_FOKUS_URL
      this.fokusVisible = true
    }
  }
}
</script>
<style>
</style>
